<template>
  <div class="container">
    <div style="
        padding-top: 5%;
        font-size: 40px;
        font-weight: bold;
        color: #475aa7;
        text-align: center;
      ">
      <p style="margin: 0; padding: 0">乐享签</p>
    </div>
    <el-card class="login-form-layout" style="height: 390px">
      <el-image v-if="!flag" class="login_account" style="width: 40px; height: 40px; float: right" :src="loginScan"
        fit="cover" @click="changeMethod"></el-image>
      <el-image v-if="!flag" style="width: 90px; height: 40px; float: right" :src="scan" fit="contain"></el-image>
      <el-image v-if="flag" class="login_account" style="width: 40px; height: 40px; float: right" :src="loginAccount"
        fit="cover" @click="changeMethod"></el-image>
      <el-image v-if="flag" style="width: 90px; height: 40px; float: right" :src="password" fit="contain"></el-image>
      <div style="clear: both"></div>
      <el-form v-if="!flag" autocomplete="on" :model="loginForm" ref="loginForm" label-position="left">
        <h2 class="login-title color-main">账号密码登录</h2>
        <el-form-item prop="user">
          <el-input name="user" clearable type="text" v-model="loginForm.user" autocomplete="on"
            placeholder="请输入用户名/电话/邮箱" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input name="password" show-password :type="pwdType" v-model="loginForm.password" autocomplete="on"
            placeholder="请输入密码" prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-select v-model="loginvalue" style="width: 100%">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <div style="text-align: right; margin-right: 10px; margin-bottom: 10px">
          <el-checkbox v-model="checked">自动登录</el-checkbox>
        </div>

        <el-form-item style="margin-bottom: 60px; text-align: center">
          <el-button style="width: 80%" type="primary" :loading="loading" @click="handleLogin">登录</el-button>
        </el-form-item>
      </el-form>
      <Vcode :show="isShow" @success="onSuccess" @close="onClose" />
      <el-form>
        <h2 class="login-title color-main">二维码登录</h2>
        <div v-if="onAvatar">
          <div class="qrcode" ref="qrCodeUrl"></div>
          <div style="text-align: center; margin-top: 10px; margin-bottom: 10px">
            <span style="color: #666; font-size: 10px">打开享签小程序扫描二维码登录</span>
          </div>
          <div style="text-align: right; margin-right: 30px; margin-bottom: 10px">
            <el-checkbox v-model="checked" @change="onChange">自动登录</el-checkbox>
          </div>
        </div>
        <div v-else style="text-align: center">
          <el-avatar shape="square" :size="150" fit="cover" :src="avatarUrl"></el-avatar>
          <div style="text-align: center; margin-top: 10px; margin-bottom: 10px">
            <span style="color: #666; font-size: 10px">请在小程序上确认</span>
          </div>
        </div>
      </el-form>
    </el-card>
    <div style="
        clear: both;
        bottom: 0px;
        width: 100%;
        position: absolute;
        text-align: center;
      ">
      <a href="http://beian.miit.gov.cn" style="color: #999">浙ICP备2024127318号</a>
    </div>
  </div>
</template>

<script>
import { isvalidUsername } from '@/utils/validate'
import QRCode from 'qrcodejs2'
import Cookies from 'js-cookie'
import Vcode from 'vue-puzzle-vcode'
import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'

Vue.use(VueCryptojs)
var qrcode

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    QRCode,
    Vcode
  },
  name: 'login',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!isvalidUsername(value)) {
        callback(new Error('请输入正确的用户名'))
      } else {
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value.length < 3) {
        callback(new Error('密码不能小于3位'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        user: '',
        password: ''
      },
      loginRules: {
        user: [
          { required: true, trigger: 'blur', validator: validateUsername }
        ],
        password: [{ required: true, trigger: 'blur', validator: validatePass }]
      },
      loading: false,
      pwdType: 'password',
      dialogVisible: false,
      supportDialogVisible: false,
      options: [
        {
          value: '1',
          label: '普通用户'
        },
        {
          value: '2',
          label: '管理员'
        }
      ],
      loginvalue: '1',
      loginAccount: require('../../assets/loginAccount.png'),
      loginScan: require('../../assets/loginScan.png'),
      password: require('../../assets/password.png'),
      scan: require('../../assets/scan.png'),
      flag: true,
      text: '',

      path: 'wss://xiangqian.club:8001/ws/asset',
      socket: '',
      status: '',

      onAvatar: true,
      avatarUrl: '',
      checked: true,

      isShow: false,

      onWrong: 0
    }
  },
  created() {
    this.screenWidth = document.body.clientWidth
    this.screenHeight = document.body.clientHeight
    this.checkCookie()
    var text1 = this.CryptoJS.AES.encrypt(
      'Hi There!',
      'Secret Passphrase'
    ).toString()
    console.log(
      '打印msg',
      text1 +
      '< /n >' +
      this.CryptoJS.AES.decrypt(text1, 'Secret Passphrase').toString(
        this.CryptoJS.enc.Utf8
      )
    )
  },
  destroyed() {
    this.socket.close()
  },
  mounted() {
    this.creatQrCode()
  },
  methods: {
    // socket连接
    init: function () {
      if (typeof WebSocket === 'undefined') {
        alert('您的浏览器不支持socket')
      } else {
        // 实例化socket
        this.socket = new WebSocket(this.path)
        // 监听socket连接
        this.socket.onopen = this.open
        // 监听socket消息
        this.socket.onmessage = this.getMessage
      }
    },
    open: function () {
      console.log('socket连接成功')
      this.socket.send(this.text.substring(26))
    },
    getMessage: function (msg) {
      console.log('看一看', msg)
      if (JSON.parse(msg.data).type == 'codeLogin') {
        window.sessionStorage.setItem('toCode', true)
      }
      window.sessionStorage.setItem('lid', JSON.parse(msg.data).lid)
      window.sessionStorage.setItem(
        'loginEndTime',
        JSON.parse(msg.data).endTime
      )
      window.sessionStorage.setItem('loginState', JSON.parse(msg.data).state)
      window.sessionStorage.setItem('cid', JSON.parse(msg.data).cid)
      window.sessionStorage.setItem('ccid', JSON.parse(msg.data).ccid)
      window.sessionStorage.setItem('actId', JSON.parse(msg.data).actId)
      window.sessionStorage.setItem('title', JSON.parse(msg.data).title)
      window.sessionStorage.setItem('linkType', JSON.parse(msg.data).linktype)
      window.sessionStorage.setItem('className', JSON.parse(msg.data).classname)
      if (JSON.parse(msg.data).token != undefined) {
        this.$message.success('登录成功!')
        window.sessionStorage.setItem('loginvalue', '1')
        window.sessionStorage.setItem('role', JSON.parse(msg.data).roleid)
        this.$store.commit('SET_ROLES', JSON.parse(msg.data).roleid)
        window.sessionStorage.setItem(
          'isExamine',
          JSON.parse(msg.data).isExamine
        )
        if (this.checked) {
          Cookies.set('Authorization', JSON.parse(msg.data).token)
          Cookies.set('avatar', JSON.parse(msg.data).avatar)
          Cookies.set('uid', JSON.parse(msg.data).uid)
          Cookies.set('role', JSON.parse(msg.data).roleid)
          Cookies.set('socketConnection', 0)
          Cookies.set('loginvalue', '1')
        }

        window.sessionStorage.setItem('myimg', JSON.parse(msg.data).avatar)
        window.sessionStorage.setItem('uid', JSON.parse(msg.data).uid)
        sessionStorage.setItem('Authorization', JSON.parse(msg.data).token)
        window.sessionStorage.setItem('socketConnection', 0)
        // this.$router.push({ path: '/signList/signList' })
        // this.$router.push({ path: '/signList/courseList' })
        this.$router.push({ path: '/mycourse/courseList' })
      } else {
        this.avatarUrl = JSON.parse(msg.data).avatar
        this.onAvatar = false
      }
    },
    onChange(e) { },

    checkCookie() {
      console.log('cookie', Cookies.get().onWrong == 'NaN')
      if (Cookies.get().Authorization != undefined) {
        var userinfo = Cookies.get()
        window.sessionStorage.setItem('uid', userinfo.uid)
        window.sessionStorage.setItem('myimg', userinfo.avatar)
        window.sessionStorage.setItem('role', userinfo.role)
        window.sessionStorage.setItem('Authorization', userinfo.Authorization)
        window.sessionStorage.setItem('loginvalue', userinfo.loginvalue)
        if (userinfo.role == '-1') {
          this.$router.push({ path: '/home/home' })
        } else if (userinfo.role == '2') {
          // this.$router.push({ path: '/signList/signList' })
          // this.$router.push({ path: '/signList/courseList' })
          this.$router.push({ path: '/mycourse/courseList' })
        }
      } else if (Cookies.get().onWrong != 'NaN') {
        this.onWrong = parseInt(Cookies.get().onWrong)
      } else {
        Cookies.set('onWrong', '0')
        this.onWrong = 0
      }
    },

    showPwd() {
      if (this.pwdType === 'password') {
        this.pwdType = ''
      } else {
        this.pwdType = 'password'
      }
    },

    async onLogin() {
      var that = this
      console.log(this.$refs.loginForm)
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          this.loading = true
          sessionStorage.setItem('loginvalue', this.loginvalue)
          if (this.loginForm.user != 'administrator') {
            const { data: res } = await that.$http.post('auth/loginWeb', {
              uid1: this.loginForm.user,
              password: this.loginForm.password
            })
            console.log('loginvalue是1的时候的结果', res)
            if (res.code == 200) {
              if (that.checked) {
                Cookies.set('Authorization', res.data.token)
                Cookies.set('avatar', res.data.avatar)
                Cookies.set('uid', res.data.uid)
                Cookies.set('role', res.data.roleId)
                Cookies.set('loginvalue', '1')
              }
              Cookies.set('onWrong', '0')
              this.onWrong = 0
              window.sessionStorage.setItem('loginvalue', '1')
              window.sessionStorage.setItem('myimg', res.data.avatar)
              window.sessionStorage.setItem('uid', res.data.uid)
              sessionStorage.setItem('role', res.data.roleId)
              this.$store.commit('SET_ROLES', res.data.roleId)
              sessionStorage.setItem('Authorization', res.data.token)
              that.$message.success('登录成功!')
              console.log(
                '从store中获取的角色信息',
                this.$store.state.user.roles
              )
              that.loading = false
              // that.$router.push({ path: '/signList/signList' })
              // this.$router.push({ path: '/signList/courseList' })
              this.$router.push({ path: '/mycourse/courseList/courseList' })
            } else {
              that.onWrong = that.onWrong + 1
              Cookies.set('onWrong', that.onWrong)
              that.$message.error('登录失败!')
              that.loginForm.user = ''
              that.loginForm.password = ''
              setTimeout(() => {
                that.loading = false
              }, 1000)
              return
            }
          } else if (this.loginForm.user == 'administrator') {
            const { data: res } = await this.$http.post(
              'auth/adminLogin',
              this.loginForm
            )
            console.log('loginvalue是2的时候的结果', res)
            if (res.code == 200) {
              console.log('checked', res.data)
              if (that.checked) {
                Cookies.set('Authorization', res.data)
                Cookies.set('loginvalue', '2')
                Cookies.set('role', '-1')
              }
              Cookies.set('onWrong', '0')
              window.sessionStorage.setItem('loginvalue', '2')
              sessionStorage.setItem('Authorization', res.data)
              sessionStorage.setItem('role', -1)
              this.$store.commit('SET_ROLES', -1)
              that.$message.success('登录成功!')
              console.log(
                '从store中获取的角色信息',
                this.$store.state.user.roles
              )
              that.loading = false
              that.$router.push({ path: '/home/home' })
            } else {
              that.onWrong = that.onWrong + 1
              Cookies.set('onWrong', that.onWrong)

              that.$message.error('登录失败!')
              setTimeout(() => {
                that.loading = false
              }, 2000)
              return
            }
          }
        } else {
          that.$message.success('参数验证不合法!')
          return false
        }
      })
    },

    // 用户通过了验证
    onSuccess(msg) {
      this.isShow = false // 通过验证后，需要手动隐藏模态框
      this.onLogin()
    },
    // 用户点击遮罩层，应该关闭模态框
    onClose() {
      this.isShow = false
    },
    // 点击登录
    handleLogin() {
      if (this.onWrong >= 3) {
        this.isShow = true
      } else {
        this.onLogin()
      }
      console.log('onwrong', this.onWrong)
    },
    // 获取登录二维码
    async getCode() {
      const { data: res } = await this.$http.get('auth/getLoginCode')
      if (res.code == 200) {
        this.text = res.data
        this.init()
      }
    },
    dialogConfirm() {
      this.dialogVisible = false
      setSupport(true)
      // window.location.href=SupportUrl;
    },
    dialogCancel() {
      this.dialogVisible = false
      setSupport(false)
    },
    // 二维码和账号密码切换登录，flag==true 二维码
    changeMethod() {
      this.flag = !this.flag
    },
    // 绘制二维码
    async creatQrCode() {
      await this.getCode()
      qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.text,
        image: '/src/assets/default_head_circle.png',
        width: this.screenWidth * 0.1,
        height: this.screenWidth * 0.1,
        colorDark: '#222',
        colorLight: 'white',
        correctLevel: QRCode.CorrectLevel.H
      })
      this.qrcode = qrcode
    }
  }
}
</script>

<style scoped>
page {
  margin: 0;
  padding: 0;
}

.container {
  background-image: url('https://img-blog.csdnimg.cn/20201224163951278.png?x-oss-process=image/watermark,type_ZmFuZ3poZW5naGVpdGk,shadow_10,text_aHR0cHM6Ly9ibG9nLmNzZG4ubmV0L3loMDAxNg==,size_16,color_FFFFFF,t_70#pic_center');
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-attachment: fixed;
}

.login-form-layout {
  /* position: absolute; */
  /* left: 0;
  right: 0; */
  float: right;
  width: 360px;
  margin-right: 10%;
  margin-top: 5%;
  border-radius: 10px;
  /* border-top: 10px solid #409eff; */
}

.login-title {
  text-align: center;
}

.login-center-layout {
  background: #409eff;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin-top: 200px;
}

.login_account {
  cursor: pointer;
}

.qrcode {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
</style>
